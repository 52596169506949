import InflationPage from 'components/pages/inflation/InflationPage';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const Index = () => (
	<div>
		{/*<Seo*/}
		{/*	title='Protect money from inflation'*/}
		{/*	keywords=''*/}
		{/*	description=''*/}
		{/*	lang='en-US'*/}
		{/*	locale='en_US'*/}
		{/*/>*/}
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>

		<InflationPage />
	</div>
);

export default Index;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
